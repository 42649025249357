<template>
  <div>
    <Dropdown style="width: 100%;" v-model="selectedProvider" :options="providers" optionLabel="name" placeholder="Select a Provider" :showClear="true" @change="changeProvider">
      <template #value="slotProps">
        <div class="country-item country-item-value" v-if="slotProps.value">
          <datafeed-provider-logo :provider="slotProps.value"></datafeed-provider-logo>
        </div>
        <span v-else>
            {{slotProps.placeholder}}
        </span>
      </template>
      <template #option="slotProps">
        <datafeed-provider-logo :provider="slotProps.option"></datafeed-provider-logo>
      </template>
    </Dropdown>
  </div>
</template>
<script>

import DatafeedProviderLogo from "./DatafeedProviderLogo";
import { ref } from 'vue';
export default {
  components: {DatafeedProviderLogo},
  // pull in the provider property
    props:['providers', 'feed'],
    setup(props, context) {
      const selectedProvider = ref();
      const changeProvider = (event) => {
        context.emit("updateProvider", { feed: props.feed, value: event.value});
      }
      return {
        selectedProvider,
        changeProvider
      }
    }
}
</script>
<style scoped>

</style>
