<template>
      <div>
        <Skeleton  v-if="loading" class="mb-2" height="6rem" borderRadius="16px"></Skeleton>
        <DataTable v-if="!loading" :value="data" columnResizeMode="fit" class="rd-table">
            <Column style="width:40%" field="name" header="Provider" key="provider">
              <template #body="slotProps">
                <div v-if="slotProps.data.newFeed">
                  <datafeed-provider-select :feed="slotProps.data" :providers="providers" @update-provider="updateProvider"></datafeed-provider-select>
                </div>
                <div v-else>
                  <datafeed-provider-logo :provider="providerFromID(slotProps.data.providerID)"></datafeed-provider-logo>
                </div>
              </template>
            </Column>
            <Column style="width:20%" field="providerRef" header="Provider Ref">
              <template #body="slotProps">
                <div v-if="slotProps.data.edit">
                  <InputText type="text" placeholder="Provider Reference" v-model="slotProps.data.editValue"></InputText>
                </div>
                <div v-else>
                  <span class="body-text">{{ slotProps.data.providerRef }}</span>
                </div>
              </template>
            </Column>
            <Column style="width:20%" field="status" header="Status">
              <template #body="slotProps">
                <div v-if="slotProps.data.newFeed">
                </div>
                <div v-else>
                  <datafeed-status :status="statusFromID(slotProps.data.status)"></datafeed-status>
                </div>
              </template>
            </Column>
            <Column style="width:20%" header="Actions">
              <template #body="slotProps">
                <div v-if="slotProps.data.newFeed">
                  <div class="flex align-items-center">
                    <submit-button style="padding: 0 45px;" @submitted="saveNewDataFeed(slotProps.data)" :loading="loadingState" button-text="Save" ></submit-button>
                    <Button class="clarity-close-modal-btn ml-5" @click="cancelNewDataFeed(slotProps.data)"><span class="material-icons-round grey">close</span></Button>
                  </div>
                </div>
                <div v-else-if="slotProps.data.edit">
                  <div class="flex align-items-center">
                    <submit-button style="padding: 0 45px;" @submitted="updateDataFeed(slotProps.data)" :loading="loadingState" button-text="Save" ></submit-button>
                    <Button class="clarity-close-modal-btn ml-5" @click="cancelUpdate(slotProps.data)"><span class="material-icons-round grey">close</span></Button>
                  </div>
                </div>
                <div v-else>
                  <!-- use slot props here to pass the object we are looking at into the click functions for delete/update examples -->
                  <span @click="startUpdate(slotProps.data)" class="material-icons hand-pointer grey" v-tooltip.top="'Update Data Feed'">edit</span>
                  <span @click="toggleModal('datafeedDelete',slotProps.data)" class="material-icons hand-pointer grey ml-3" v-tooltip.top="'Delete Data Feed'">delete_forever</span>
                </div>
              </template>
            </Column>
            <template #footer>
                <div class="flex justify-content-center clarity-margin-bottom-s">
                  <Button class="clarity-btn clarity-gradient-orange" style="padding: 0 45px;" @click="createDataFeed"><span class="material-icons-round white mr-3">add</span>Add Data Feed</Button>
                </div>
            </template>
        </DataTable>

        <Dialog v-model:visible="showModal.datafeedDelete" class="delete-dialog">
          <div class="flex justify-content-between modal-header">
            <h3 class="blue">Confirm Delete</h3>
            <Button class="clarity-close-modal-btn" @click="closeModal"><span class="material-icons-round grey">close</span></Button>
          </div>
          <div class="message">Are you sure you want to delete this data feed?</div>
          <div class="button-group">
            <Button class="clarity-btn clarity-gradient-grey" @click="closeModal">Dismiss</Button>
            <submit-button @submitted="confirmDelete" :loading="loadingState" button-text="Confirm Action" loading-text="Removing Feed..." ></submit-button>
          </div>
        </Dialog>

        <Dialog v-model:visible="showAddError" class="add-error-dialog">
          <div class="flex justify-content-between modal-header">
            <h3 class="blue">Missing Information</h3>
          </div>
          <div class="message">Please ensure you have selected a provider and entered the provider reference.</div>
          <div class="button-group">
            <Button class="clarity-btn clarity-gradient-grey" @click="hideAddError">Close</Button>
          </div>
        </Dialog>
    </div>
</template>
<script>

import {useStore} from "vuex";
import api from '@/core/services/api'
import {computed, reactive, ref, watch} from 'vue';
import { toDrupalRequest } from '@/core/helpers';
import DatafeedProviderLogo from "./DatafeedProviderLogo";
import DatafeedStatus from "./DatafeedStatus";
import DatafeedProviderSelect from "./DatafeedProviderSelect";
import SubmitButton from "@/components/common/layout/interactive/SubmitButton";
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {SubmitButton, DatafeedProviderSelect, DatafeedStatus, DatafeedProviderLogo},
  // pull in the two properties.
    props:['data', 'providers', 'status', 'loading'],
    setup(props) {

      const store = useStore()

      const blockAdd = ref(true);

      // ######################## DELETE LOGIC HERE ######################
      const deleteModal = ref(false);
      const showAddError = ref(false);
      const hideAddError = () => {
        showAddError.value = false;
      }

      const showModal = reactive({
        datafeedDelete: false,
        datafeedEdit: false
      })

      const activeModal = computed(() => store.getters.currentActiveModal);
      const loadingState = computed(() => store.getters.isLoading('DatafeedTable'))

      watch(activeModal, (value) => {
        // empty object returned if not set
        if(typeof value == 'object')  {
          showModal.datafeedDelete = false
          showModal.datafeedEdit = false
        } else {
          showModal[value] = true
        }
      })

      const toggleModal = (modal, feed) => {
        if (modal === 'datafeedDelete' && feed) {
          deleteModal.value = feed
        }
        store.dispatch('setActiveModal', {modal: modal})
      }

      const closeModal = () => {
        deleteModal.value = false
        store.dispatch('setActiveModal')
      }

      // call delete function here.
      const confirmDelete = async () => {
        if(Object.prototype.hasOwnProperty.call(deleteModal.value, "id")) {

          store.dispatch('setLoading', {id: 'DatafeedTable', state: true})

          if(process.env.VUE_APP_DELAY_BACKEND_API) {
            await new Promise(r => setTimeout(r, 2000));
          }

          // pass the feed ID as the param when making the delet request.
          const endpoint = '/valuation/datafeeds/' + deleteModal.value.id;
          const response = await api.delete(`${toDrupalRequest(endpoint)}`)

          // check response was a success
          if (response === undefined) {
            await store.dispatch('addNotification', {
              severity: 'error',
              summary: 'Delete Data Feed',
              detail: `Unable to delete the data feed.`
            })
            store.dispatch('setLoading', {id: 'DatafeedTable', state: false})
            closeModal()
          } else {
            // on success dispatch the delete action accordingly providing the feed object to be deleted.
            await store.dispatch('deleteDataFeed', deleteModal.value)
            // confirm deletion
            await store.dispatch('addNotification', {
              severity: 'success',
              summary: 'Portfolio Deleted',
              detail: ``,
              life: 4000
            })
            store.dispatch('setLoading', {id: 'DatafeedTable', state: false})
            closeModal()
          }
        }else{
          await store.dispatch('addNotification', {severity: 'error', summary: 'Delete Data Feed', detail: `Unable to delete the data feed.`})
          store.dispatch('setLoading', {id: 'DatafeedTable', state: false})
          closeModal()
        }
      }
      // ######################## DELETE LOGIC HERE ######################

      // ######################## CREATE LOGIC HERE ######################

      // create data feed method here
      const createDataFeed = async () => {

        // on success dispatch the add feed action with the payload data.
        store.dispatch('addDataFeed', {
          id: uuidv4(),
          // feed id and provider id going forward should be the same but historically were not always the same.
          feedID: null,
          providerRef: null,
          // note status is set to 1 whenever a feed changes so this is hard coded.
          status: "1",
          // feed id and provider id going forward should be the same but historically were not always the same.
          providerID: null,
          edit: true,
          editValue: null,
          newFeed: true,
          newProvider: null
        })

      }

      const saveNewDataFeed = async (feed) => {

        // check that the right stuff has been set.
        if(feed.newProvider && feed.editValue){

          store.dispatch('setLoading', {id: 'DatafeedTable', state: true})

          // currently hard coding the new feed ID and provider Ref. Would need to be added and passed to this function.
          const feedID = feed.newProvider;
          const providerRef = feed.editValue;

          if(process.env.VUE_APP_DELAY_BACKEND_API) {
            await new Promise(r => setTimeout(r, 2000));
          }

          // pass data to the end point
          const endpoint = '/valuation/datafeeds';
          const response = await api.post(
              `${toDrupalRequest(endpoint)}`,
              JSON.stringify({feedID: feedID, providerReference: providerRef}),
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              })

          // check for valid response.
          if(!response) {
            await store.dispatch('addNotification', {severity: 'error', summary: 'Create Data Feed', detail: `Unable to create new data feed`})
          }else {
            if(response.error){
              await store.dispatch('addNotification', {severity: 'error', summary: 'Create Data Feed', detail: `Unable to create new data feed`})
            }else if (response.success) {
              // on success update the data feed with the newly returned info.
              await store.dispatch('updateDataFeed', {
                id: feed.id,
                feedID: feedID,
                providerRef: providerRef,
                status: "1",
                providerID: feedID,
                newid: response.success
              })

              // add success toast
              await store.dispatch('addNotification', {
                severity: 'success',
                summary: 'Data Feed Created',
                detail: `With reference ${providerRef}`,
                life: 4000
              })
            }
          }
          store.dispatch('setLoading', {id: 'DatafeedTable', state: false})

        }else{
          showAddError.value = true;
        }

      }

      const cancelNewDataFeed = (feed) => {
        store.dispatch('deleteDataFeed', feed)
      }

      const updateProvider = (data) => {
        if(data.value) {
          data.feed.newProvider = data.value.id;
        }else{
          data.feed.newProvider = null
        }
      }

      // ######################## CREATE LOGIC HERE ######################

      // ######################## UPDATE LOGIC HERE ######################
      const startUpdate = (feed) => {
        feed.edit = true;
        feed.editValue = feed.providerRef;
      }
      const cancelUpdate = (feed) => {
        feed.edit = false;
      }
      // update method set out here
      const updateDataFeed = async (feed) => {
        // hard coding the updated reference number for now, would need to be set in the UI.
        const newRef = feed.editValue;

        if(feed.editValue === feed.providerRef) {
          await store.dispatch('addNotification', {
            severity: 'success',
            summary: 'Portfolio Updated',
            detail: ``,
            life: 4000
          })
          cancelUpdate(feed);
        }else{
          store.dispatch('setLoading', {id: 'DatafeedTable', state: true})

          if(process.env.VUE_APP_DELAY_BACKEND_API) {
            await new Promise(r => setTimeout(r, 2000));
          }

          // use feed id to send to the endpoint as well as stringify the payload body with the new reference argument.
          const endpoint = '/valuation/datafeeds/' + feed.id;
          const response = await api.patch(
              `${toDrupalRequest(endpoint)}`,
              JSON.stringify({newReference: newRef}),
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              })

          if (!response) {
            await store.dispatch('addNotification', {severity: 'error', summary: 'Update Data Feed', detail: `Unable to update data feed`})
          } else {
            if (response.error) {
              await store.dispatch('addNotification', {severity: 'error', summary: 'Update Data Feed', detail: `Unable to update data feed`})
            } else if (response.success == true) {
              // dispatch update data feed action
              await store.dispatch('updateDataFeed', {
                id: feed.id,
                feedID: feed.feedID,
                providerRef: newRef,
                status: "1",
                providerID: feed.providerID,
              })
              // and confirm success.
              await store.dispatch('addNotification',{severity: 'success', summary: 'Portfolio Updated', detail: ``, life: 4000})
            }
          }
          store.dispatch('setLoading', {id: 'DatafeedTable', state: false})

        }
      }

      // ######################## UPDATE LOGIC HERE ######################

      const providerFromID = (id) => {
          // interim fix for the trust feedfs bug.
          if(id == '16'){
              id = '15';
          }else if(id == '9'){
              id = '8'
          }
          return props.providers.find(provider => provider.id === id);
      }

      const statusFromID = (id) => {
          return props.status.find(status => status.id === id);
      }

      return {
        confirmDelete,
        cancelNewDataFeed,
        createDataFeed,
        saveNewDataFeed,
        startUpdate,
        cancelUpdate,
        updateDataFeed,
        updateProvider,
        providerFromID,
        statusFromID,
        blockAdd,
        deleteModal,
        showAddError,
        hideAddError,
        activeModal,
        toggleModal,
        closeModal,
        showModal,
        loadingState
      }

    }
}
</script>
<style scoped>
  .feed-status p {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
  }
  .hand-pointer {
    cursor: pointer;
  }
  button.save {
    width: 70%;
  }
  button.clarity-btn {
    /*width: 210px;*/
    padding: 15px 45px;
  }
</style>
