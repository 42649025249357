<template>
  <div class="p-5 flex flex-column">
    <InvestmentTopCard/>

    <spacer-h/>
    <div id="rd3-scrollto"></div>
    <div>
      <content-card-full>
        <template #title>
          <card-title>
            <template #title><h2>FUND PROVIDER DATA FEEDS</h2></template>
          </card-title>
        </template>
        <template #content-full>
          <div class="flex">
            <!-- LEFT -->
            <div class="flex" style="width: 50%;">
              <div class="flex flex-column justify-content-between ">
                <div>
                  <h3 class="mb-3">INTRODUCTION</h3>
                  <div class="body pr-5">Welcome to the Fund Provider data feeds section of our website. This page displays the details of the data feeds currently included in your online valuation. We currently provide valuation history reporting for Aegon, Fidelity Adviser Solutions, Transact, James Hay, Insignis, Moventum and some Aviva holdings as part of the online data feeds. Add a data feed by selecting the Fund Provider and entering your provider reference number. Please note that it may take up to 24 hours for the data to feed through to your online valuation. You can add more than one data feed on this page. Green circle represents that the data feed is active, orange circle means the data feed is in pending state, red circle means that the data feed is no longer active.
                  </div>
                </div>
                  <!--
                <div class="flex my-5">
                  <Button class="clarity-btn clarity-gradient-grey">Read more</Button>
                </div>
                -->
              </div>
            </div>
            <!-- RIGHT -->
            <div class="flex" style="width: 50%;">
              <div class="portfolioManagementVideo">
                <iframe src="https://player.vimeo.com/video/878608801?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="clarity - Portfolio Management" style="width:100%;height:100%;"></iframe>
              </div>   
            </div>
          </div>

          <spacer-h/>
          <!-- DATAFEEDS TABLE -->
          <div>
            <h3 class="dark-grey">MANAGE DATA FEEDS</h3>
            <p class="body-text">Listed below are your current data feeds, you can edit these by using the<span class="material-icons grey" style="vertical-align: text-bottom;">edit</span>icon or you can delete these feeds by using the<span class="material-icons grey" style="vertical-align: text-bottom;">delete_forever</span>icon.</p>
            <DatafeedsTable :data="dataFeeds" :providers="datafeedProviders" :status="datafeedStatus"
                            :loading="dataFeedsLoading"/>
          </div>

          <spacer-h/>

          <div id="data-feed-info">
            <div class="clarity-margin-bottom-m clarity-margin-top-s">
              <h3 class="dark-grey">PLATFORM PROVIDERS</h3>
              <h5 class="blue">Available Online:</h5>
            </div>
            <div class="flex flex-wrap">
              <template  v-for="providers in onlineProviders"
                   :key="providers.id">
                <div class="flex clarity-margin-bottom-m column-providers" >
                  <div class="provider-logo-box flex align-items-center justify-content-center">
                    <img class="provider-logo-image" :src="providers.logo">
                  </div>
                  <div class="flex" style="width: 70%">
                    <div class="flex flex-column flex-grow-1 justify-content-between clarity-margin-right-m data-feed-info-body">
                      <h3>{{ providers.name }}</h3>
                      <p>{{ providers.description }}</p>
                      <div class="flex">
                        <Button class="clarity-btn clarity-gradient-orange" @click="investWith(providers.name); $router.push('/investments/add/terms');">Invest Now
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div>
              <h5 class="blue">Offline only:</h5>
            </div>
            <div class="flex flex-wrap">
              <template  v-for="providers in offlineProviders"
                   :key="providers.id" >
                <div class="flex clarity-margin-bottom-m column-providers" v-if="providers.id != 3">
                  <div class="provider-logo-box flex align-items-center justify-content-center">
                    <img class="provider-logo-image" :src="providers.logo">
                  </div>
                  <div class="flex" style="width: 70%">
                    <div class="flex flex-column flex-grow-1 justify-content-between clarity-margin-right-m data-feed-info-body">
                      <h3>{{ providers.name }}</h3>
                      <p>{{ providers.description }}</p>
                      <div class="flex">
                        <Button class="clarity-btn clarity-gradient-grey" @click="$router.push('/securemessaging/message'); contactAdviser(providers.name);">Contact your Adviser
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>

          </div>
        </template>
      </content-card-full>
    </div>

    <HelpCard mode="investments" class="pt-5" />
  </div>
</template>
<script>
import ContentCardFull from '@/components/common/ContentCardFull.vue'
import CardTitle from '@/components/common/CardTitle.vue'
import SpacerH from '@/components/common/layout/SpacerH.vue'
import DatafeedsTable from '@/components/datafeeds/DatafeedTable.vue'

import api from '@/core/services/api'
import {toDrupalRequest} from '@/core/helpers';
import {computed, onMounted, ref} from 'vue';
import {useStore} from 'vuex';
import InvestmentTopCard from "@/components/top_cards/InvestmentTopCard";
import HelpCard from "@/components/common/HelpCard";


export default {
  setup() {
    const datafeedProviders = ref([]);
    const datafeedStatus = ref([]);

    const store = useStore()

    // data feeds array and loading state is gathered from the store via the getters.
    const dataFeeds = computed(() => store.getters.dataFeeds)
    const dataFeedsLoading = computed(() => store.state.datafeeds.dataFeedsLoading)

    const getProviders = async () => {
      const endpoint = '/valuation/datafeedplatforms';
      const response = await api.get(`${toDrupalRequest(endpoint)}`);
      if (response.error) {
        console.log('error');
      }

      datafeedProviders.value = response.providers;
      datafeedStatus.value = response.status;
    }

    // on mount ensure the data feeds call is made!
    onMounted(() => {
      // dispatch the action to load the data feeds.
      store.dispatch('getDataFeeds')
      getProviders();
    })

    const onlineProviders = computed(() => {
      return datafeedProviders.value.filter(provider => provider.active == 1 && provider.online == true);
    });

    const offlineProviders = computed(() => {
      return datafeedProviders.value.filter(provider => provider.active == 1 && provider.online == false);
    })

    const contactAdviser = (provider) => {
      store.dispatch('setSelectedStep', {stepper: 'investnowredirect', step: 2, completed: 1})
      store.dispatch('globalMessageTrigger',  {
        subject: 'Invest Now - ' + provider,
        category: 'Investments',
        recipient: store.getters.getAdviserName,
        messagebody: 'I would like to discuss investing via the '+provider+' platform.'
      })
    }

    const investWith = (provider) => {
      store.dispatch('setInvestNowProvider', provider)
      store.dispatch('setSelectedStep', {stepper: 'investnow', step: 2, completed: 1})
    }


    return {
      getProviders,
      datafeedProviders,
      dataFeeds,
      dataFeedsLoading,
      datafeedStatus,
      onlineProviders,
      offlineProviders,
      investWith,
      contactAdviser
    }
  },
  components: {
    InvestmentTopCard,
    ContentCardFull,
    CardTitle,
    SpacerH,
    DatafeedsTable,
    HelpCard
  }
}
</script>
<style scoped>
.content-container {
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.content {
  display: flex;
}

.content-body {
  padding-top: 20px;
  padding-bottom: 20px;
}

button {
  min-width: 220px;
  justify-content: center;
  margin: 5px 5px 5px 0px;
}

.card-bottom {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
}

.bottom-title {
  display: flex;
  min-width: max-content;
  padding-right: 5%;
}

.bottom-actions {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.bottom-actions button {
  width: calc(100% / 4);
  padding: 0px 45px;
  margin: 0px 15px;
}

.body {
  font-size: 18px;
  line-height: 28px;
}

.provider-logo-box {
  width: 300px !important;
  height: 120px;
  margin-right: 30px;
  background: var(--clarity-pure-white) 0% 0% no-repeat padding-box;
  border: 1px solid var(--clarity-light-grey);
  border-radius: 27px;
  text-align: center;
}

.provider-logo-box img {
  height: auto;
  max-height: 60%;
  max-width: 240px;
}

.column-providers {
  width: 50%;
}
.portfolioManagementVideo {
  width: 100%;
  height: auto;
  padding: 20px;
}
@media(max-width: 1200px) {
  .column-providers {
    width: 100%;
  }
}
</style>
