<template>
  <div>
    <div v-if="status">
      <div class="feed-status" v-if="status.class == 1">
        <div class="statusIcon statusGreen" v-tooltip.top="{value: status.description, class:'statusGreen'}"></div>
        <p class="body-text">Okay</p>
      </div>
      <div class="feed-status" v-if="status.class == 0">
        <div class="statusIcon statusOrange" v-tooltip.top="{value: status.description, class:'statusOrange'}"></div>
        <p class="body-text">In progress</p>
      </div>
      <div class="feed-status" v-if="status.class == 2">
        <div class="statusIcon statusRed" v-tooltip.top="{value: status.description, class:'statusRed'}"></div>
        <p class="body-text">Action required</p>
      </div>
    </div>
    <div v-else>
      Loading
    </div>
  </div>
</template>
<script>


export default {// pull in the two properties.
  props:['status']
}
</script>
<style scoped>
.feed-status p {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0px;
}
.statusIcon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 24px;
  height: 24px;
  border-radius: 50% 50%;
}
.statusGreen {
  background: transparent linear-gradient(180deg, #4BD988 0%, var(--clarity-green) 100%) 0% 0% no-repeat padding-box;
}
.statusOrange {
  background: transparent linear-gradient(180deg, #FFBA62 0%, var(--clarity-orange) 100%) 0% 0% no-repeat padding-box;
}
.statusRed {
  background: transparent linear-gradient(180deg, var(--clarity-red) 0%, #AF292F 100%) 0% 0% no-repeat padding-box;
}
</style>
