<template>
  <div>
    <div v-if="provider">
      <div class="flex">
        <div class="provider-logo flex-none flex align-items-center justify-content-center" style="width: 174px;">
          <img :src="provider.logo">
        </div>
        <div class="flex-grow-1 flex align-items-center">
          <span class="body-text provider-logo-text blue bold">{{ provider.name }}</span>
        </div>
      </div>
    </div>
    <div v-else>
      Loading
    </div>
  </div>
</template>
<script>

export default {
    // pull in the provider property
    props:['provider']
}
</script>
<style scoped>
  .provider-logo {
    height: 44px;
    border: 1px solid var(--clarity-light-grey);
    border-radius: 40px 40px 40px 40px;
    padding: 5px 20px;
    vertical-align: middle;
    background-color: var(--clarity-pure-white);
  }
  .provider-logo img{
    height: auto;
    max-height: 90%;
    max-width: 110px;
  }
  .provider-logo-text {
    vertical-align: middle;
    margin-left: 30px;
    font-weight: var(--clarity-font-weight-bold);
  }
</style>
